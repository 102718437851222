import { useState, useRef } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  theme,
} from "@chakra-ui/react"
import { keyframes } from '@emotion/react';

const growAndFlash = keyframes`
  -100%, 50% {
    transform: scale(1);
    opacity: 1;
  }
  25% {
    transform: scale(1.5);
    opacity: 0;
  }
  50% {
    transform: scale(0);
    opacity: 1;
  }
  75% {
    transform: scale(-1.5);
    opacity: 1;
  }
  100% {
    transform: scale(3);
    opacity: 1;
  }
`;

export const App = () => {
  const [playing, setPlaying] = useState(false);
  const videoRef: any = useRef(null);

  const togglePlay = () => {
    if (playing) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setPlaying(!playing);
  };

  return (
    <ChakraProvider theme={theme}>
      <Box
        position="fixed"
        top="0"
        right="0"
        bottom="0"
        left="0"
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundColor="black"
      >
        <video
          ref={videoRef}
          onClick={togglePlay}
          width="100%"
          height="100%"
          style={{
            objectFit: 'cover', // This will cover the entire viewport without losing the aspect ratio
            cursor: 'pointer'
          }}
          src="./poor.mp4" // Replace this with the path to your video file
          loop
        >
          Your browser does not support the video tag.
        </video>
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          color="white"
          p={4}
          bg="rgba(0, 0, 0, 0.5)"
          borderRadius="lg"
          css={{
            animation: playing ? `${growAndFlash} 1s infinite` : 'none',
            animationDelay: '27s', 
            transformOrigin: 'center',
          }}
        >
          <Text fontSize="40px" fontWeight="bold" textAlign="center">
            WE CALL THEM POOR
          </Text>
        </Box>
      </Box>
    </ChakraProvider>
  );
}
